const {
  GET_PURCHASEEVENT_SUCCESS,
  GET_PURCHASEEVENT_FAIL,
  GET_PURCHASEEVENT_APPRENTICE_DETAIL_SUCCESS,
  GET_PURCHASEEVENT_APPRENTICE_DETAIL_FAIL,
  DELETE_PURCHASEEVENT_SUCCESS,
  DELETE_PURCHASEEVENT_FAIL,
} = require("./actionTypes")

const INIT_STATE = {
  purchaseEvent: [],
  purchaseEventError: [],
  purchaseEventApprentice: [],
  purchaseEventApprenticeError: [],
  deletePurchaseError: [],
}

const Purchase = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PURCHASEEVENT_SUCCESS:
      return {
        ...state,
        purchaseEvent: action.payload,
      }
    case GET_PURCHASEEVENT_FAIL:
      return {
        ...state,
        purchaseEventError: action.payload,
      }
    case GET_PURCHASEEVENT_APPRENTICE_DETAIL_SUCCESS:
      return {
        ...state,
        purchaseEventApprentice: action.payload,
      }
    case GET_PURCHASEEVENT_APPRENTICE_DETAIL_FAIL:
      return {
        ...state,
        purchaseEventApprenticeError: action.payload,
      }
    case DELETE_PURCHASEEVENT_SUCCESS:
      return {
        ...state,
        purchaseEvent: state.purchaseEvent.filter(
          event => event.id.toString() !== action.payload.toString(),
        ),
      }
    case DELETE_PURCHASEEVENT_FAIL:
      return {
        ...state,
        deletePurchaseError: action.payload,
      }
    default:
      return state
  }
}

export default Purchase
