import {
  GET_USER_SUBSCRIPTIONS,
  GET_USER_SUBSCRIPTIONS_SUCCESS,
  GET_USER_SUBSCRIPTIONS_FAIL,
} from "./actionTypes"

export const getUserSubscriptions = () => ({
  type: GET_USER_SUBSCRIPTIONS,
})

export const getUserSubscriptionSuccess = data => ({
  type: GET_USER_SUBSCRIPTIONS_SUCCESS,
  payload: data,
})

export const getUserSubscriptionFail = error => ({
  type: GET_USER_SUBSCRIPTIONS_FAIL,
  payload: error,
})
