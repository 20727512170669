import moment from "moment"
import { React, useEffect } from "react"
import { Col, Row } from "react-bootstrap"
import { useSelector, useDispatch } from "react-redux"
import ProfileImage from "../../../assets/images/userProfile.svg"
import { useNavigate, useSearchParams } from "react-router-dom"
import Lefts from "../../../assets/images/lefts.png"
import { getBookedEventDetail } from "store/bookedEvent/actions"
export default function BookedDetails() {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [searchParams] = useSearchParams()
  const bookingId = searchParams.get("bookingId")

  const { bookedEventDetail } = useSelector(state => ({
    bookedEventDetail: state.bookedEvents.bookedEventDetail,
  }))

  useEffect(() => {
    if (bookingId && Object.keys(bookedEventDetail).length === 0) {
      dispatch(getBookedEventDetail(bookingId))
    }
  }, [dispatch])
  return (
    <>
      <button
        onClick={() => navigate(-1)}
        style={{
          position: "relative",
          top: "95px",
          left: "21px",
          marginBottom: "120px",
          background: "#556ee6",
          width: "74px",
          height: "36px",
          border: "0",
          color: "#fff",
          fontSize: "16px",
          display: "flex",
          alignItems: "center",
          borderRadius: "8px",
        }}
      >
        <img
          src={Lefts}
          alt=""
          height="17"
          style={{ transform: "rotate(180deg)" }}
        />{" "}
        Back
      </button>
      <div className="boxed">
        <div className="heading">
          <h3>Booked Event Details</h3>
        </div>
        <Row className="muiTag">
          <Col md={12}>
            <Col>
              <img
                src={
                  bookedEventDetail.created_by?.profile_image
                    ? bookedEventDetail.created_by?.profile_image
                    : ProfileImage
                }
                alt=""
              />
            </Col>
          </Col>
          <Col md={4}>
            <Col>
              <h4>Event Name</h4>
              <span>{bookedEventDetail.event}</span>
            </Col>
          </Col>
          <Col md={4}>
            <Col>
              <h4>Created By</h4>
              {bookedEventDetail?.instructor_details?.map(items => (
                <span key={items.id}>
                  {items.first_name} {items.last_name}
                </span>
              ))}
            </Col>
          </Col>

          <Col md={4}>
            <Col>
              <h4>Booked By</h4>
              <span>
                {bookedEventDetail.created_by?.first_name}{" "}
                {bookedEventDetail.created_by?.last_name}
              </span>
            </Col>
          </Col>
          <Col md={4}>
            <Col>
              <h4>Booking Type</h4>
              <span>{bookedEventDetail.booking_type}</span>
            </Col>
          </Col>

          <Col md={4}>
            <Col>
              <h4>Booking Date</h4>
              <span>{bookedEventDetail.booking_date}</span>
            </Col>
          </Col>
          <Col md={4}>
            <Col>
              <h4>Booking Time</h4>
              <span>
                {moment(bookedEventDetail.booking_time, "hh:mm:ss").format(
                  "hh:mm A",
                )}
              </span>
            </Col>
          </Col>
        </Row>
      </div>
    </>
  )
}
