import { call, put, takeEvery } from "redux-saga/effects"

// Ecommerce Redux States
import {
  GET_PROFILE,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_FAIL,
  UPDATE_PROFILE,
} from "./actionTypes"
import {
  getProfile,
  getProfileSuccess,
  getProfileFail,
  updateProfileSuccess,
  updateProfileFail,
} from "./actions"

import { showLoader, hideLoader } from "store/actions"

import { del, get, putt } from "helpers/api_helper"

function* fetchProfile() {
  try {
    yield put(showLoader())
    const response = yield call(get, "/api/v1/admin/users/profile/")

    yield put(getProfileSuccess(response))
  } catch (error) {
    yield put(getProfileFail(error))
  } finally {
    yield put(hideLoader())
  }
}

function* updateProfile({ payload: data }) {
  try {
    yield put(showLoader())
    const response = yield call(
      putt,
      `/api/v1/admin/users/profile/update/`,
      data,
    )
    // yield put(updateProfileSuccess(data))
    yield put(updateProfileSuccess(response)) // Dispatch the API response, not the input data
  } catch (error) {
    yield put(updateProfileFail(error))
  } finally {
    yield put(hideLoader())
  }
}

function* adminProfileSaga() {
  yield takeEvery(GET_PROFILE, fetchProfile)
  yield takeEvery(UPDATE_PROFILE, updateProfile)
}

export default adminProfileSaga
