import {
  GET_SMTP_SUCCESS,
  GET_SMTP_FAIL,
  UPDATE_SMTP_SUCCESS,
  UPDATE_SMTP_FAIL,
  ADD_SMTP_SUCCESS,
  ADD_SMTP_FAIL,
  RESET_SMTP_FLAG,
  GET_STRIPE_SUCCESS,
  GET_STRIPE_FAIL,
  UPDATE_STRIPE_SUCCESS,
  UPDATE_STRIPE_FAIL,
  ADD_STRIPE_SUCCESS,
  ADD_STRIPE_FAIL,
  RESET_STRIPE_FLAG,
} from "./actionTypes"

const INIT_STATE = {
  smtp: {},
  stripe: {},
  smtpError: {},
  stripeError: {},
  updateSmtpError: null,
  updateStripeError: null,
  updateSmtpSuccess: null,
  updateStripeSuccess: null,
  addSmtpError: null,
  addStripeError: null,
  addSmtpSuccess: null,
  addStripeSucess: null,
}

const adminSetting = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SMTP_SUCCESS:
      return {
        ...state,
        smtp: action.payload,
        SmtpError: null,
      }
    case GET_SMTP_FAIL:
      return {
        ...state,
        smtpError: action.payload,
      }

    case GET_STRIPE_SUCCESS:
      return {
        ...state,
        stripe: action.payload,
        stripeError: null,
      }
    case GET_STRIPE_FAIL:
      return {
        ...state,
        stripeError: action.payload,
      }

    case UPDATE_SMTP_SUCCESS:
      return {
        ...state,
        smtp: action.payload,
        updateSmtpSuccess: "updated successfully",
        updateSmtpError: null,
      }
    case UPDATE_SMTP_FAIL:
      return {
        ...state,
        updateSmtpError: action.payload,
        updateSmtpSuccess: null,
      }

    case UPDATE_STRIPE_SUCCESS:
      return {
        ...state,
        stripe: action.payload,
        updateStripeSuccess: "updated successfully",
        updateStripeError: null,
      }
    case UPDATE_STRIPE_FAIL:
      return {
        ...state,
        updateStripeError: action.payload,
        updateStripeSuccess: null,
      }

    case ADD_SMTP_SUCCESS:
      return {
        ...state,
        smtp: action.payload,
        addSmtpSuccess: "updated successfully",
        addSmtpError: null,
      }
    case ADD_SMTP_FAIL:
      return {
        ...state,
        addSmtpError: action.payload,
        addSmtpSuccess: null,
      }

    case ADD_STRIPE_SUCCESS:
      return {
        ...state,
        stripe: action.payload,
        addStripeSuccess: "updated successfully",
        addStripeError: null,
      }
    case ADD_STRIPE_FAIL:
      return {
        ...state,
        addStripeError: action.payload,
        addStripeSuccess: null,
      }

    case RESET_SMTP_FLAG:
      return {
        ...state,
        updateSmtpSuccess: null,
        updateSmtpError: null,
        addSmtpSuccess: null,
        addSmtpError: null,
      }

    case RESET_STRIPE_FLAG:
      return {
        ...state,
        updateStripeSuccess: null,
        updateStripeError: null,
        addStripeSuccess: null,
        addStripeError: null,
      }
    default:
      return state
  }
}

export default adminSetting
