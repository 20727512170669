import React from "react"
import { Spinner } from "reactstrap"

const Loader = () => (
  <div
    style={{
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "rgba(255, 255, 255, 0.8)", // semi-transparent overlay
      zIndex: 9999,
    }}
  >
    <Spinner color="primary" />
  </div>
)

export default Loader
