import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
} from "reactstrap"
import Lefts from "../../../assets/images/lefts.png"
import { useNavigate } from "react-router-dom"
import ReactQuill from "react-quill"
import { useSearchParams } from "react-router-dom"
import "react-quill/dist/quill.snow.css"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

//redux
import { useSelector, useDispatch } from "react-redux"
import Form from "react-bootstrap/Form"
import { getPlanDetail, getPlans, updatePlan } from "store/actions"
// actions
// import { editProfile, resetProfileFlag } from "../../store/actions"

export default function PlanDetails() {
  //meta title
  document.title = "Plan | Tacticulture - Admin"
  const navigate = useNavigate()

  const dispatch = useDispatch()

  const [searchParams] = useSearchParams()
  const planId = searchParams.get("planId")

  const [planName, setPlanName] = useState("")
  const [idx, setidx] = useState(null)
  const [planDescription, setPlanDescription] = useState("")
  const [planDuration, setPlanDuration] = useState("")
  const [planCost, setPlanCost] = useState("")

  const { error, success } = useSelector(state => ({
    error: state.Profile.error,
    success: state.Profile.success,
  }))

  // useEffect(() => {
  //   if (localStorage.getItem("authUser")) {
  //     const obj = JSON.parse(localStorage.getItem("authUser"))
  //     setname(obj.displayName)
  //     setemail(obj.user)
  //     setidx(obj.uid)
  //     if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
  //       setname(obj.displayName)
  //       setemail(obj.user)
  //       setidx(obj.uid)
  //     } else if (
  //       process.env.REACT_APP_DEFAULTAUTH === "fake" ||
  //       process.env.REACT_APP_DEFAULTAUTH === "jwt"
  //     ) {
  //       setname(obj.username)
  //       setemail(obj.user)
  //       setidx(obj.uid)
  //     }
  //     setTimeout(() => {
  //       console.log("timeout")
  //       // dispatch(resetProfileFlag())
  //     }, 3000)
  //   }
  // }, [dispatch, success])

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      username: name || "",
      idx: idx || "",
    },
    validationSchema: Yup.object({
      username: Yup.string().required("Please Enter Your UserName"),
    }),
    onSubmit: values => {
      console.log(values)
      //   dispatch(editProfile(values))
    },
  })

  const handlePlanDescriptionChange = value => {
    setPlanDescription(value)
  }

  const planDetail = useSelector(state => state.Plans.planDetail)

  useEffect(() => {
    if (planId && Object.keys(planDetail).length === 0) {
      dispatch(getPlanDetail(planId))
    }
  }, [dispatch, planId, planDetail])

  useEffect(() => {
    if (planDetail) {
      setPlanName(planDetail.name)
      setPlanDescription(planDetail.description)
      setPlanDuration(planDetail.plan_type)
      setPlanCost(planDetail.pricing)
    }
  }, [planDetail])

  return (
    <React.Fragment>
      <button
        onClick={() => navigate(-1)}
        style={{
          position: "relative",
          top: "95px",
          left: "21px",
          marginBottom: "20px",
          background: "#556ee6",
          width: "74px",
          height: "36px",
          border: "0",
          color: "#fff",
          fontSize: "16px",
          display: "flex",
          alignItems: "center",
          borderRadius: "8px",
        }}
      >
        <img
          src={Lefts}
          alt=""
          height="17"
          style={{ transform: "rotate(180deg)" }}
        />{" "}
        Back
      </button>
      <div className="page-content">
        <Container fluid>
          {/* <h5 className="mb-4">Profile</h5> */}
          {/* Render Breadcrumb */}
          {/* <Breadcrumb title="Profile" breadcrumbItem="" /> */}

          <Row>
            <Col lg="12">
              {error && error ? (
                <Alert
                  color="danger"
                  style={{
                    position: "fixed",
                    top: "80px",
                    right: "10px",
                    zIndex: 1000,
                  }}
                >
                  {error}
                </Alert>
              ) : null}
              {success ? (
                <Alert
                  color="success"
                  style={{
                    position: "fixed",
                    top: "80px",
                    right: "10px",
                    zIndex: 1000,
                  }}
                >
                  {success}
                </Alert>
              ) : null}

              <div className="">
                <h3
                  style={{
                    fontSize: "20px",
                    borderBottom: "1px solid #000",
                    paddingBottom: "9px",
                    margin: "18px 0",
                  }}
                >
                  Edit Plan
                </h3>
              </div>

              <Card>
                <CardBody>
                  <div className="container-fluid p-0">
                    <div className="row fieldDetails">
                      <div className="col-md-12">
                        <Form.Label>Plan Id</Form.Label>
                        <Form.Control
                          type="number"
                          value={planId}
                          placeholder="Enter Your Plan Id"
                        />
                      </div>
                      <div className="col-md-12">
                        <Form.Label>Plan Name</Form.Label>
                        <Form.Control
                          type="text"
                          value={planName}
                          onChange={e => setPlanName(e.target.value)}
                          placeholder="Enter Your Plan Name"
                        />
                      </div>
                      <div className="col-md-12">
                        <Form.Label>Plan Description</Form.Label>
                        <ReactQuill
                          showCount
                          maxLength={1000}
                          //   style={{ height: "300px" }}
                          value={planDescription}
                          onChange={handlePlanDescriptionChange}
                          placeholder="Enter Your Plan Description"
                        />
                      </div>
                      <div className="col-md-12">
                        <Form.Label>Plan Type</Form.Label>
                        <div>
                          <Form.Control
                            as="select"
                            value={planDuration}
                            onChange={e => {
                              const selectedDuration = e.target.value
                              setPlanDuration(selectedDuration)
                              // Automatically set plan cost to 0 if the selected duration is "free"
                              if (selectedDuration === "free") {
                                setPlanCost(0)
                              }
                            }}
                          >
                            <option value="">Select Type</option>
                            <option value="month">Monthly</option>
                            <option value="year">Yearly</option>
                            <option value="free">Free</option>
                          </Form.Control>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <Form.Label>Plan Cost($) </Form.Label>
                        <Form.Control
                          type="number"
                          value={planCost}
                          disabled={planDuration === "free" ? true : false}
                          onChange={e => setPlanCost(e.target.value)}
                          placeholder="Enter Your Plan Cost"
                        />
                      </div>

                      <div style={{ textAlign: "end" }}>
                        <button
                          onClick={() => {
                            const payload = {
                              id: planId,
                              name: planName,
                              description: planDescription,
                              pricing: planCost,
                              status: true,
                              plan_type: planDuration,
                            }
                            dispatch(updatePlan(payload))
                            setTimeout(() => {
                              dispatch(getPlans())
                            }, 600)
                          }}
                          style={{
                            background: "#556ee6",
                            width: "144px",
                            height: "44px",
                            border: "0",
                            fontSize: "16px",
                            borderRadius: "26px",
                            margin: "18px 0",
                            color: "#fff",
                          }}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* <h4 className="card-title mb-4">Change User Name</h4> */}

          {/* <Card>
            <CardBody>
              <Form
                className="form-horizontal"
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <div className="form-group">
                  <Label className="form-label">User Name</Label>
                  <Input
                    name="username"
                    // value={name}
                    className="form-control"
                    placeholder="Enter User Name"
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.username || ""}
                    invalid={
                      validation.touched.username && validation.errors.username
                        ? true
                        : false
                    }
                  />
                  {validation.touched.username && validation.errors.username ? (
                    <FormFeedback type="invalid">
                      {validation.errors.username}
                    </FormFeedback>
                  ) : null}
                  <Input name="idx" value={idx} type="hidden" />
                </div>
                <div className="text-center mt-4">
                  <Button type="submit" color="danger">
                    Update User Name
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card> */}
        </Container>
      </div>
    </React.Fragment>
  )
}
