/* GET PROFILE */
export const GET_PROFILE = "GET_PROFILE"
export const GET_PROFILE_SUCCESS = "GET_PROFILE_SUCCESS"
export const GET_PROFILE_FAIL = "GET_PROFILE_FAIL"

/*UPDATE PROFILE */
export const UPDATE_PROFILE = "UPDATE_PROFILE"
export const UPDATE_PROFILE__SUCCESS = "UPDATE_PROFILE__SUCCESS"
export const UPDATE_PROFILE_FAIL = "UPDATE_PROFILE_FAIL"
export const RESET_PROFILE_FLAG = "RESET_PROFILE_FLAG"
