import React from "react"
import { Link } from "react-router-dom"
import * as moment from "moment"
import { Badge } from "reactstrap"
import dummy from "../../../assets/images/users/avatar_dummy.jpeg"

const formateDate = (date, format) => {
  const dateFormat = format ? format : "DD MMM Y"
  const date1 = moment(new Date(date)).format(dateFormat)
  return date1
}
const toLowerCase1 = str => {
  return str === "" || str === undefined ? "" : str.toLowerCase()
}

const OrderId = cell => {
  return (
    <span className="text-body fw-bold">{cell.value ? cell.value : ""}</span>
  )
}

const CommonField = cell => {
  return cell.value ? cell.value : ""
}

const AmountField = cell => {
  return cell.value || cell.value === 0 ? cell.value : ""
}

const Collection = cell => {
  return cell.value !== 0 ? cell.value : 0
}

const ApprenticeDetails = cell => {
  if (cell.value) {
    const { first_name, last_name, email, phone_number } = cell.value
    return (
      <div>
        <div>
          {first_name} {last_name}
        </div>
        <div style={{ fontSize: "small", color: "gray" }}>{email}</div>
        <div style={{ fontSize: "small", color: "gray" }}>{phone_number}</div>
      </div>
    )
  }
  return ""
}
const InstructorDetails = cell => {
  if (cell.value) {
    const { first_name, last_name, email, phone_number } = cell.value[0]
    return (
      <div>
        <div>
          {first_name} {last_name}
        </div>
        <div style={{ fontSize: "small", color: "gray" }}>{email}</div>
        <div style={{ fontSize: "small", color: "gray" }}>{phone_number}</div>
      </div>
    )
  }
  return ""
}

const BookingAmount = cell => {
  return cell.value || cell.value === 0 ? `$ ${cell.value}` : ""
}

const EventName = cell => {
  return cell.value ? cell.value : ""
}

const Date = cell => {
  return cell.value ? moment(cell.value).format("DD MMM y") : ""
}

const Image = cell => {
  return cell.value ? (
    <img
      src={cell.value}
      alt="User Avatar"
      className="rounded-circle img-fluid"
      width={100}
    />
  ) : (
    <img
      src={dummy}
      alt="User Avatar"
      className="rounded-circle img-fluid"
      width={100}
    />
  )
}

const Total = cell => {
  return cell.value ? cell.value : ""
}

const PaymentStatus = cell => {
  return (
    <Badge
      className={
        "font-size-12 badge-soft-" +
        (cell.value === "Paid"
          ? "success"
          : "danger" && cell.value === "Refund"
            ? "warning"
            : "danger")
      }
    >
      {cell.value}
    </Badge>
  )
}
const PaymentMethod = cell => {
  return (
    <span>
      <i
        className={
          cell.value === "Paypal"
            ? "fab fa-cc-paypal me-1"
            : "" || cell.value === "COD"
              ? "fab fas fa-money-bill-alt me-1"
              : "" || cell.value === "Mastercard"
                ? "fab fa-cc-mastercard me-1"
                : "" || cell.value === "Visa"
                  ? "fab fa-cc-visa me-1"
                  : ""
        }
      />{" "}
      {cell.value}
    </span>
  )
}
export {
  OrderId,
  EventName,
  Date,
  Total,
  PaymentStatus,
  PaymentMethod,
  CommonField,
  AmountField,
  Image,
  InstructorDetails,
  ApprenticeDetails,
  BookingAmount,
  Collection,
}
