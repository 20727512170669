import { React, useEffect } from "react"
import { Col, Row } from "react-bootstrap"
import { useSelector, useDispatch } from "react-redux"
import { Link, useSearchParams } from "react-router-dom"
import Lefts from "../../../assets/images/lefts.png"
import { getUserDetail } from "store/actions"
export default function UserDetails() {
  const dispatch = useDispatch()

  const [searchParams] = useSearchParams()
  const userId = searchParams.get("userId") // Get eventId from the query parameters

  const { userDetail } = useSelector(state => ({
    userDetail: state.users.userDetail,
  }))

  useEffect(() => {
    if (userId && Object.keys(userDetail).length === 0) {
      dispatch(getUserDetail(userId))
    }
  }, [dispatch])
  return (
    <>
      <Link to={"/users"} style={{ textDecoration: "none" }}>
        <div
          style={{
            position: "relative",
            top: "95px",
            left: "21px",
            marginBottom: "120px",
            background: "#556ee6",
            width: "77px",
            height: "36px",
            border: "0",
            color: "#fff",
            fontSize: "16px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "8px",
            cursor: "pointer",
          }}
        >
          <img
            src={Lefts}
            alt="Back"
            height="17"
            style={{ transform: "rotate(180deg)", marginRight: "8px" }}
          />{" "}
          Back
        </div>
      </Link>
      <div className="boxed">
        <div className="heading">
          <h3>User Details</h3>
        </div>
        <Row className="muiTag">
          <Col className="tagsEvent">
            <Col className="innerBoxed">
              <img src={userDetail.profile_image} alt="" />
            </Col>
            <Col className="innerBoxed">
              <h4>First Name:</h4>
              <span>{userDetail.first_name}</span>
            </Col>
            <Col className="innerBoxed">
              <h4>Last Name:</h4>
              <span>{userDetail.last_name}</span>
            </Col>
            <Col className="innerBoxed">
              <h4>User Name</h4>
              {userDetail.default_profile === "apprentice" ? (
                <a
                  href={`${process.env.REACT_APP_FRONTEND_URL}/apprentice-profile?userId=${userDetail.id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {userDetail.username}
                </a>
              ) : (
                <a
                  href={`${process.env.REACT_APP_FRONTEND_URL}/instructor-profile?userId=${userDetail.id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {userDetail.username}
                </a>
              )}
              {/* <span>{userDetail.username}</span> */}
            </Col>
            <Col className="innerBoxed">
              <h4>Email Address:</h4>
              <span>{userDetail.email}</span>
            </Col>
            <Col className="innerBoxed">
              <h4>Phone Number:</h4>
              <span>{userDetail.phone_number}</span>
            </Col>
            <Col className="innerBoxed">
              <h4>Profile:</h4>
              <span>{userDetail.default_profile}</span>
            </Col>
            <Col className="innerBoxed">
              <h4>Category:</h4>
              <span>
                {userDetail.events?.map(el => {
                  return (
                    <div key={el.id}>
                      <p>{el.event_categories}</p>
                    </div>
                  )
                })}
              </span>
            </Col>
            <Col className="innerBoxed">
              <h4>User Plan:</h4>
              <span>{userDetail.subscription?.plan?.name}</span>
              <p>{userDetail.subscription?.plan?.plan_type}</p>
            </Col>
          </Col>
        </Row>
      </div>

      <div className="boxed" style={{ marginTop: "3px" }}>
        <div className="heading">
          <h3>User Profile</h3>
        </div>
        {userDetail.default_profile === "apprentice" ? (
          <iframe
            src={`${process.env.REACT_APP_FRONTEND_URL}/apprentice-profile-embed?userId=${userDetail.id}`}
            width="100%"
            height="600"
            frameBorder="0"
            allowFullScreen
            title="Course Page"
          />
        ) : (
          <iframe
            src={`${process.env.REACT_APP_FRONTEND_URL}/instructor-profile-embed?userId=${userDetail.id}`}
            width="100%"
            height="600"
            frameBorder="0"
            allowFullScreen
            title="Course Page"
          />
        )}
      </div>
    </>
  )
}
