import {
  GET_EVENTS_SUCCESS,
  GET_EVENTS_FAIL,
  GET_EVENT_DETAIL_SUCCESS,
  GET_EVENT_DETAIL_FAIL,
  DELETE_EVENT_SUCCESS,
  DELETE_EVENT_FAIL,
  UPDATE_EVENT_SUCCESS,
  UPDATE_EVENT_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  events: [],
  eventsError: {},
  eventDetail: {},
  eventDetailError: {},
  eventDeleteError: {},
}

const Events = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_EVENTS_SUCCESS:
      return {
        ...state,
        events: action.payload,
      }
    case GET_EVENTS_FAIL:
      return {
        ...state,
        eventsError: action.payload,
      }
    case UPDATE_EVENT_SUCCESS:
      return {
        ...state,
        events: state.events.map(el =>
          el.id === action.payload.eventId
            ? { ...el, is_event_active: action.payload.value }
            : el,
        ),
      }
    case UPDATE_EVENT_FAIL:
      return {
        ...state,
      }
    case GET_EVENT_DETAIL_SUCCESS:
      return {
        ...state,
        eventDetail: action.payload,
      }
    case GET_EVENT_DETAIL_FAIL:
      return {
        ...state,
        eventDetailError: action.payload,
      }
    case DELETE_EVENT_SUCCESS:
      return {
        ...state,
        events: state.events.filter(
          event => event.id.toString() !== action.payload.toString(),
        ),
      }
    case DELETE_EVENT_FAIL:
      return {
        ...state,
        eventDeleteError: action.payload,
      }
    // case GET_PRODUCTS_SUCCESS:
    //   return {
    //     ...state,
    //     products: action.payload,
    //   }

    // case GET_PRODUCTS_FAIL:
    //   return {
    //     ...state,
    //     error: action.payload,
    //   }

    // case GET_PRODUCT_DETAIL_SUCCESS:
    //   return {
    //     ...state,
    //     product: action.payload,
    //   }

    // case GET_PRODUCT_DETAIL_FAIL:
    //   return {
    //     ...state,
    //     error: action.payload,
    //   }

    // case GET_ORDERS_SUCCESS:
    //   return {
    //     ...state,
    //     orders: action.payload,
    //   }

    // case GET_ORDERS_FAIL:
    //   return {
    //     ...state,
    //     error: action.payload,
    //   }

    // case ADD_ORDER_SUCCESS:
    //   return {
    //     ...state,
    //     orders: [...state.orders, action.payload],
    //   }

    // case ADD_ORDER_FAIL:
    //   return {
    //     ...state,
    //     error: action.payload,
    //   }

    // case UPDATE_ORDER_SUCCESS:
    //   return {
    //     ...state,
    //     orders: state.orders.map(order =>
    //       order.id + "" === action.payload.id + ""
    //         ? { order, ...action.payload }
    //         : order
    //     ),
    //   }

    // case UPDATE_ORDER_FAIL:
    //   return {
    //     ...state,
    //     error: action.payload,
    //   }

    // case DELETE_ORDER_SUCCESS:
    //   return {
    //     ...state,
    //     orders: state.orders.filter(order => order.id !== action.payload),
    //   }

    // case DELETE_ORDER_FAIL:
    //   return {
    //     ...state,
    //     error: action.payload,
    //   }

    // case GET_CART_DATA_SUCCESS:
    //   return {
    //     ...state,
    //     cartData: action.payload,
    //   }

    // case GET_CART_DATA_FAIL:
    //   return {
    //     ...state,
    //     error: action.payload,
    //   }

    // case GET_CUSTOMERS_SUCCESS:
    //   return {
    //     ...state,
    //     customers: action.payload,
    //   }

    // case GET_CUSTOMERS_FAIL:
    //   return {
    //     ...state,
    //     error: action.payload,
    //   }

    // case ADD_CUSTOMER_SUCCESS:
    //   return {
    //     ...state,
    //     customers: [...state.customers, action.payload],
    //   }

    // case ADD_CUSTOMER_FAIL:
    //   return {
    //     ...state,
    //     error: action.payload,
    //   }

    // case UPDATE_CUSTOMER_SUCCESS:
    //   return {
    //     ...state,
    //     customers: state.customers.map(customer =>
    //       customer.id.toString() === action.payload.id.toString()
    //         ? { customer, ...action.payload }
    //         : customer
    //     ),
    //   }

    // case UPDATE_CUSTOMER_FAIL:
    //   return {
    //     ...state,
    //     error: action.payload,
    //   }

    // case DELETE_CUSTOMER_SUCCESS:
    //   return {
    //     ...state,
    //     customers: state.customers.filter(
    //       customer => customer.id.toString() !== action.payload.toString()
    //     ),
    //   }

    // case DELETE_CUSTOMER_FAIL:
    //   return {
    //     ...state,
    //     error: action.payload,
    //   }

    // case GET_SHOPS_SUCCESS:
    //   return {
    //     ...state,
    //     shops: action.payload,
    //   }

    // case GET_SHOPS_FAIL:
    //   return {
    //     ...state,
    //     error: action.payload,
    //   }

    // case GET_PRODUCT_COMMENTS_SUCCESS:
    // case ON_LIKE_COMMENT_SUCCESS:
    // case ON_LIKE_REPLY_SUCCESS:
    // case ON_ADD_REPLY_SUCCESS:
    // case ON_ADD_COMMENT_SUCCESS:
    //   return {
    //     ...state,
    //     productComments: action.payload,
    //   }

    // case GET_PRODUCT_COMMENTS_FAIL:
    //   return {
    //     ...state,
    //     error: action.payload,
    //   }

    default:
      return state
  }
}

export default Events
