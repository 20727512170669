import {
  GET_USER_SUBSCRIPTIONS_SUCCESS,
  GET_USER_SUBSCRIPTIONS_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  userSubscriptions: [],
  userSubscriptionError: {},
}

const UsersSubscriptions = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_USER_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        userSubscriptions: action.payload,
      }
    case GET_USER_SUBSCRIPTIONS_FAIL:
      return {
        ...state,
        userSubscriptionError: action.payload,
      }
    default:
      return state
  }
}

export default UsersSubscriptions
