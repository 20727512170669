import {
  GET_SMTP,
  GET_SMTP_SUCCESS,
  GET_SMTP_FAIL,
  UPDATE_SMTP,
  UPDATE_SMTP_SUCCESS,
  UPDATE_SMTP_FAIL,
  RESET_SMTP_FLAG,
  ADD_SMTP,
  ADD_SMTP_SUCCESS,
  ADD_SMTP_FAIL,
  GET_STRIPE,
  GET_STRIPE_SUCCESS,
  GET_STRIPE_FAIL,
  UPDATE_STRIPE,
  UPDATE_STRIPE_SUCCESS,
  UPDATE_STRIPE_FAIL,
  RESET_STRIPE_FLAG,
  ADD_STRIPE,
  ADD_STRIPE_SUCCESS,
  ADD_STRIPE_FAIL,
} from "./actionTypes"

export const getSmtp = () => ({
  type: GET_SMTP,
})

export const getSmtpSuccess = smtp => ({
  type: GET_SMTP_SUCCESS,
  payload: smtp,
})

export const getSmtpFail = error => ({
  type: GET_SMTP_FAIL,
  payload: error,
})

export const getStripe = () => ({
  type: GET_STRIPE,
})

export const getStripeSuccess = stripe => ({
  type: GET_STRIPE_SUCCESS,
  payload: stripe,
})

export const getStripeFail = error => ({
  type: GET_STRIPE_FAIL,
  payload: error,
})

export const updateSmtp = value => ({
  type: UPDATE_SMTP,
  payload: value,
})

export const updateSmtpSuccess = data => ({
  type: UPDATE_SMTP_SUCCESS,
  payload: data,
})

export const updateSmtpFail = error => ({
  type: UPDATE_SMTP_FAIL,
  payload: error,
})

export const updateStripe = value => ({
  type: UPDATE_STRIPE,
  payload: value,
})

export const updateStripeSuccess = data => ({
  type: UPDATE_STRIPE_SUCCESS,
  payload: data,
})

export const updateStripeFail = error => ({
  type: UPDATE_STRIPE_FAIL,
  payload: error,
})

export const addSmtp = value => ({
  type: ADD_SMTP,
  payload: value,
})

export const addSmtpSuccess = data => ({
  type: ADD_SMTP_SUCCESS,
  payload: data,
})

export const addSmtpFail = error => ({
  type: ADD_SMTP_FAIL,
  payload: error,
})

export const resetSmtpAlert = () => ({
  type: RESET_SMTP_FLAG,
})

export const addStripe = value => ({
  type: ADD_STRIPE,
  payload: value,
})

export const addStripeSuccess = data => ({
  type: ADD_STRIPE_SUCCESS,
  payload: data,
})

export const addStripeFail = error => ({
  type: ADD_STRIPE_FAIL,
  payload: error,
})

export const resetStripeAlert = () => ({
  type: RESET_STRIPE_FLAG,
})
