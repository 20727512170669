import { call, put, takeEvery } from "redux-saga/effects"

// admin settings Redux States
import {
  GET_SMTP,
  GET_STRIPE,
  UPDATE_SMTP,
  UPDATE_STRIPE,
  ADD_SMTP,
  ADD_STRIPE,
} from "./actionTypes"
import {
  getSmtpSuccess,
  getStripeSuccess,
  getSmtpFail,
  getStripeFail,
  updateSmtpSuccess,
  updateStripeSuccess,
  updateSmtpFail,
  updateStripeFail,
  addSmtpSuccess,
  addStripeSuccess,
  addSmtpFail,
  addStripeFail,
} from "./actions"

import { showLoader, hideLoader } from "store/actions"

import { get, putt, post } from "helpers/api_helper"

function* fetchSmtp() {
  try {
    yield put(showLoader())
    const response = yield call(get, "/api/v1/admin/users/smtp-configurations/")

    yield put(getSmtpSuccess(response.results[0]))
  } catch (error) {
    yield put(getSmtpFail(error))
  } finally {
    yield put(hideLoader())
  }
}

function* fetchStripe() {
  try {
    yield put(showLoader())
    const response = yield call(get, "/api/v1/site-settings/stripe-keys/")

    yield put(getStripeSuccess(response.results[0]))
  } catch (error) {
    yield put(getStripeFail(error))
  } finally {
    yield put(hideLoader())
  }
}

function* updateSmtp({ payload: data }) {
  try {
    yield put(showLoader())
    const response = yield call(
      putt,
      `/api/v1/admin/users/smtp-configurations/${data.id}/`,
      data,
    )
    // yield put(updateProfileSuccess(data))
    yield put(updateSmtpSuccess(response)) // Dispatch the API response, not the input data
  } catch (error) {
    yield put(updateSmtpFail(error))
  } finally {
    yield put(hideLoader())
  }
}

function* updateStripe({ payload: data }) {
  try {
    yield put(showLoader())
    const response = yield call(
      putt,
      `/api/v1/site-settings/stripe-keys/${data.id}/`,
      data,
    )
    yield put(updateStripeSuccess(response))
  } catch (error) {
    yield put(updateStripeFail(error))
  } finally {
    yield put(hideLoader())
  }
}

function* addSmtp({ payload: data }) {
  try {
    yield put(showLoader())
    const response = yield call(
      post,
      `/api/v1/admin/users/smtp-configurations/`,
      data,
    )
    // yield put(updateProfileSuccess(data))
    yield put(addSmtpSuccess(response))
  } catch (error) {
    yield put(addSmtpFail(error))
  } finally {
    yield put(hideLoader())
  }
}

function* addStripe({ payload: data }) {
  try {
    yield put(showLoader())
    const response = yield call(
      post,
      `/api/v1/site-settings/stripe-keys/`,
      data,
    )
    // yield put(updateProfileSuccess(data))
    yield put(addStripeSuccess(response))
  } catch (error) {
    yield put(addStripeFail(error))
  } finally {
    yield put(hideLoader())
  }
}
function* adminSettingSaga() {
  yield takeEvery(GET_SMTP, fetchSmtp)
  yield takeEvery(UPDATE_SMTP, updateSmtp)
  yield takeEvery(ADD_SMTP, addSmtp)

  yield takeEvery(GET_STRIPE, fetchStripe)
  yield takeEvery(UPDATE_STRIPE, updateStripe)
  yield takeEvery(ADD_STRIPE, addStripe)
}

export default adminSettingSaga
