/* GET SMTP details */
export const GET_SMTP = "GET_SMTP"
export const GET_SMTP_SUCCESS = "GET_SMTP_SUCCESS"
export const GET_SMTP_FAIL = "GET_SMTP_FAIL"
/* GET Stripe details */
export const GET_STRIPE = "GET_STRIPE"
export const GET_STRIPE_SUCCESS = "GET_STRIPE_SUCCESS"
export const GET_STRIPE_FAIL = "GET_STRIPE_FAIL"

/*UPDATE SMTP details */
export const UPDATE_SMTP = "UPDATE_SMTP"
export const UPDATE_SMTP_SUCCESS = "UPDATE_SMTP_SUCCESS"
export const UPDATE_SMTP_FAIL = "UPDATE_SMTP_FAIL"
export const RESET_SMTP_FLAG = "RESET_SMTP_FLAG"
/*UPDATE Stripe details */
export const UPDATE_STRIPE = "UPDATE_STRIPE"
export const UPDATE_STRIPE_SUCCESS = "UPDATE_STRIPE_SUCCESS"
export const UPDATE_STRIPE_FAIL = "UPDATE_STRIPE_FAIL"
export const RESET_STRIPE_FLAG = "RESET_STRIPE_FLAG"

/*Add SMTP details if not exist */
export const ADD_SMTP = "ADD_SMTP"
export const ADD_SMTP_SUCCESS = "ADD_SMTP_SUCCESS"
export const ADD_SMTP_FAIL = "ADD_SMTP_FAIL"
/*Add Stripe details if not exist */
export const ADD_STRIPE = "ADD_STRIPE"
export const ADD_STRIPE_SUCCESS = "ADD_STRIPE_SUCCESS"
export const ADD_STRIPE_FAIL = "ADD_STRIPE_FAIL"
