// import axios from "axios"
// // import accessToken from "./jwt-token-access/accessToken"
// import Authorization from "./jwt-token-access/auth-token-header"
// //pass new generated access token here
// const token = Authorization()

// //apply base url for axios
// // const au = Authorization()
// const API_URL = "http://digimonk.live:2301"

// const axiosApi = axios.create({
//   baseURL: API_URL,
// })

// axiosApi.defaults.headers.common["Authorization"] = token

// axiosApi.interceptors.response.use(
//   response => response,
//   error => Promise.reject(error)
// )

// export async function get(url, config = {}) {
//   return { const token = Authorization(),
//     await axiosApi.get(url, { ...config }).then(response => response.data)
//   }
// }

// export async function post(url, data, config = {}) {
//   return axiosApi
//     .post(url, { ...data }, { ...config })
//     .then(response => {
//       return response.data
//     })
//     .catch(err => console.log(err, "err ye hai kya"))
// }

// export async function put(url, data, config = {}) {
//   return axiosApi
//     .put(url, { ...data }, { ...config })
//     .then(response => response.data)
// }

// export async function putt(url, data, config = {}) {
//   return axiosApi
//     .put(url, { ...data }, { ...config })
//     .then(response => response.data)
// }

// export async function del(url, config = {}) {
//   return await axiosApi
//     .delete(url, { ...config })
//     .then(response => response.data)
// }

import axios from "axios"
import Authorization from "./jwt-token-access/auth-token-header"

//apply base url for axios
// const au = Authorization()
const API_URL = process.env.REACT_APP_SERVER_URL
const axiosApi = axios.create({
  baseURL: API_URL,
})

axiosApi.interceptors.request.use(
  config => {
    // Add authorization header to every request
    config.headers.Authorization = Authorization()
    return config
  },
  error => {
    return Promise.reject(error)
  },
)

axiosApi.interceptors.response.use(
  response => response,
  error => {
    console.log("status")
    const status = error.response ? error.response.status : null
    // Check for token expiration (401 or 403)
    if (status === 401 || status === 403) {
      console.log("Token expired or unauthorized, logging out...")
      localStorage.removeItem("authUser")
      window.location.href("/login")
    }

    return Promise.reject(error)
  },
)

export async function get(url, config = {}) {
  return axiosApi.get(url, { ...config }).then(response => response.data)
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => {
      return response.data
    })
    .catch(err => console.log(err, "err ye hai kya"))
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function putt(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function del(url, config = {}) {
  return axiosApi.delete(url, { ...config }).then(response => response.data)
}
