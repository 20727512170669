import {
  GET_PLANS_SUCCESS,
  GET_PLANS_FAIL,
  GET_PLAN_DETAIL_SUCCESS,
  GET_PLAN_DETAIL_FAIL,
  DELETE_PLAN_SUCCESS,
  DELETE_PLAN_FAIL,
  UPDATE_PLAN__SUCCESS,
  UPDATE_PLAN_FAIL,
  ADD_PLAN_SUCCESS,
  ADD_PLAN_FAIL,
  RESET_ADD_PLAN_FLAG,
} from "./actionTypes"

const INIT_STATE = {
  plans: [],
  plansError: {},
  planDetail: {},
  planDetailError: {},
  planDeleteError: {},
  addPlanError: null,
}

const Plans = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PLANS_SUCCESS:
      return {
        ...state,
        plans: action.payload,
      }
    case GET_PLANS_FAIL:
      return {
        ...state,
        plansError: action.payload,
      }

    case UPDATE_PLAN__SUCCESS:
      return {
        ...state,
        planDetail: action.payload,
      }
    case UPDATE_PLAN_FAIL:
      return {
        ...state,
      }
    case GET_PLAN_DETAIL_SUCCESS:
      return {
        ...state,
        planDetail: action.payload,
      }
    case GET_PLAN_DETAIL_FAIL:
      return {
        ...state,
        planDetailError: action.payload,
      }
    case DELETE_PLAN_SUCCESS:
      return {
        ...state,
        plans: state.plans.filter(
          plan => plan.id.toString() !== action.payload.toString(),
        ),
      }
    case DELETE_PLAN_FAIL:
      return {
        ...state,
        planDeleteError: action.payload,
      }

    case ADD_PLAN_SUCCESS:
      return {
        ...state,
        plans: [...state.plans, action.payload],
        addPlanError: null,
      }
    case ADD_PLAN_FAIL:
      return {
        ...state,
        addPlanError: "Something Went Wrong",
      }

    case RESET_ADD_PLAN_FLAG:
      return {
        ...state,
        addPlanError: null,
      }

    default:
      return state
  }
}

export default Plans
