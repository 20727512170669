import { React, useEffect } from "react"
import { Col, Row } from "react-bootstrap"
import { useSelector, useDispatch } from "react-redux"
import { Link, useSearchParams } from "react-router-dom"
import Lefts from "../../../assets/images/lefts.png"
import { getEventDetail } from "store/actions"
export default function EventDetails() {
  const dispatch = useDispatch()

  const [searchParams] = useSearchParams()
  const eventId = searchParams.get("eventId") // Get eventId from the query parameters

  const { eventDetail } = useSelector(state => ({
    eventDetail: state.events.eventDetail,
  }))

  useEffect(() => {
    if (eventId && Object.keys(eventDetail).length === 0) {
      dispatch(getEventDetail(eventId))
    }
  }, [dispatch])

  return (
    <>
      <Link to={"/events"} style={{ textDecoration: "none" }}>
        <div
          style={{
            position: "relative",
            top: "95px",
            left: "21px",
            marginBottom: "120px",
            background: "#556ee6",
            width: "77px",
            height: "36px",
            border: "0",
            color: "#fff",
            fontSize: "16px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "8px",
            cursor: "pointer",
          }}
        >
          <img
            src={Lefts}
            alt="Back"
            height="17"
            style={{ transform: "rotate(180deg)", marginRight: "8px" }}
          />{" "}
          Back
        </div>
      </Link>
      <div className="boxed">
        <div className="heading">
          <h3>Event Details</h3>
        </div>
        <Row className="muiTag">
          <Col className="tagsEvent">
            <Col className="innerBoxed">
              <img src={eventDetail.event_image} alt="" />
            </Col>
            <Col className="innerBoxed">
              <h4>Event Name</h4>
              <span>{eventDetail.name}</span>
            </Col>
            <Col className="innerBoxed">
              <h4>Course Url:</h4>
              <a
                href={`${eventDetail.course_url}?courseUrl=${eventDetail.course_url}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <p>{eventDetail.course_url}</p>
              </a>
            </Col>
            <Col className="innerBoxed">
              <h4>Created By:</h4>
              <a
                href={`${process.env.REACT_APP_FRONTEND_URL}/instructor-profile?userId=${eventDetail?.instructor_details?.id}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <p> {eventDetail?.instructor_details?.username}</p>
              </a>
            </Col>
            <Col className="innerBoxed">
              <h4>Creator Phone Number:</h4>
              <span>{eventDetail.instructor_details?.phone_number}</span>
            </Col>
            <Col className="innerBoxed">
              <h4>Event Type:</h4>
              <span>{eventDetail.event_type_and_schedule_id}</span>
            </Col>
            <Col className="innerBoxed">
              <h4>Custom Questions:</h4>
              <span>
                {eventDetail.custom_questions?.map(el => {
                  return (
                    <div key={el.id}>
                      <p>{el.fieldType}</p>
                      <p style={{ Paddingleft: 10 }}>
                        {el.questionPromptLabel}
                      </p>
                    </div>
                  )
                })}
              </span>
            </Col>
            <Col className="innerBoxed">
              <h4>Cost per spot:</h4>
              <span>{eventDetail.cost_per_spot}</span>
            </Col>
            <Col className="innerBoxed">
              <h4>Available Spot:</h4>
              <span>{eventDetail.available_spots}</span>
            </Col>
          </Col>
        </Row>
      </div>

      <div className="boxed" style={{ marginTop: "3px" }}>
        <div className="heading">
          <h3>Event Page</h3>
        </div>
        <iframe
          src={`${process.env.REACT_APP_FRONTEND_URL}/embeded-event?courseUrl=${eventDetail.course_url}`}
          width="100%"
          height="600"
          frameBorder="0"
          allowFullScreen
          title="Course Page"
        />
      </div>
    </>
  )
}
