import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
} from "reactstrap"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"
import {
  getSmtp,
  updateSmtp,
  addSmtp,
  resetSmtpAlert,
  getStripe,
  updateStripe,
  resetStripeAlert,
} from "store/actions"

//redux
import { useSelector, useDispatch } from "react-redux"
import withRouter from "components/Common/withRouter"
import Form from "react-bootstrap/Form"
import UserLogo from "../../assets/images/userLogo.png"
// actions

const AdminSetting = () => {
  //meta title
  document.title = "Settings | Tacticulture - Admin"

  const dispatch = useDispatch()

  // States for SMTP
  const [id, setId] = useState(null)
  const [host, setHost] = useState(0)
  const [port, setPort] = useState("")
  const [userName, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [email, setEmail] = useState("")

  // States for Stripe
  const [stripeId, setStripeId] = useState("")
  const [publishKey, setPublishKey] = useState("")
  const [secretKey, setSecretKey] = useState("")
  // const [currencyCode, setCurrencyCode] = useState("")
  const [productId, setProductId] = useState("")
  const [webhookSecret, setWebhookSecret] = useState("")

  // const [email, setemail] = useState("")
  // const [name, setname] = useState("")
  // const [idx, setidx] = useState(1)

  // const { error, success } = useSelector(state => ({
  //   error: state.Profile.error,
  //   success: state.Profile.success,
  // }))

  // useEffect(() => {
  //   if (localStorage.getItem("authUser")) {
  //     const obj = JSON.parse(localStorage.getItem("authUser"))
  //     setname(obj.displayName)
  //     setemail(obj.user)
  //     setidx(obj.uid)
  //     if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
  //       setname(obj.displayName)
  //       setemail(obj.user)
  //       setidx(obj.uid)
  //     } else if (
  //       process.env.REACT_APP_DEFAULTAUTH === "fake" ||
  //       process.env.REACT_APP_DEFAULTAUTH === "jwt"
  //     ) {
  //       setname(obj.username)
  //       setemail(obj.user)
  //       setidx(obj.uid)
  //     }
  //     setTimeout(() => {
  //       dispatch(resetProfileFlag())
  //     }, 3000)
  //   }
  // }, [dispatch, success])

  // const validation = useFormik({
  //   // enableReinitialize : use this flag when initial values needs to be changed
  //   enableReinitialize: true,

  //   initialValues: {
  //     username: name || "",
  //     idx: idx || "",
  //   },
  //   validationSchema: Yup.object({
  //     username: Yup.string().required("Please Enter Your UserName"),
  //   }),
  //   onSubmit: values => {
  //     dispatch(editProfile(values))
  //   },
  // })

  const {
    smtp,
    stripe,
    updateSmtpError,
    updateStripeError,
    updateSmtpSuccess,
    updateStripeSuccess,
    addSmtpSuccess,
    addStripeSuccess,
    addSmtpError,
    addStripeError,
  } = useSelector(state => ({
    smtp: state.adminSetting.smtp,
    stripe: state.adminSetting.stripe,
    updateSmtpError: state.adminSetting.updateSmtpError?.response?.data,
    updateStripeError: state.adminSetting.updateStripeError?.response?.data,
    updateSmtpSuccess: state.adminSetting.updateSmtpSuccess,
    updateStripeSuccess: state.adminSetting.updateStripeSuccess,
    addSmtpSuccess: state.adminSetting.addSmtpSuccess,
    addStripeSuccess: state.adminSetting.addStripeSuccess,
    addSmtpError: state.adminSetting.addSmtpError?.response?.data,
    addStripeError: state.adminSetting.addStripeError?.response?.data,
  }))

  useEffect(() => {
    // if (adminProfile) {
    dispatch(getSmtp())
    dispatch(getStripe())
    // }
  }, [dispatch])

  useEffect(() => {
    if (
      updateSmtpError ||
      updateSmtpSuccess ||
      addSmtpError ||
      addSmtpSuccess ||
      updateStripeError ||
      updateStripeSuccess ||
      addStripeError ||
      addStripeSuccess
    ) {
      const timer = setTimeout(() => {
        dispatch(resetSmtpAlert())
        dispatch(resetStripeAlert())
      }, 5000)

      return () => clearTimeout(timer)
    }
  }, [
    updateSmtpError,
    updateSmtpSuccess,
    addSmtpError,
    addSmtpSuccess,
    updateStripeError,
    updateStripeSuccess,
    addStripeError,
    addStripeSuccess,
    dispatch,
  ])

  useEffect(() => {
    if (smtp) {
      setId(smtp.id || null)
      setHost(smtp.smtp_host || "")
      setPort(smtp.smtp_port || "")
      setUsername(smtp.smtp_username || "")
      setPassword(smtp.smtp_password || "")
      setEmail(smtp.default_email || "")
    }
  }, [smtp])

  useEffect(() => {
    if (stripe) {
      setStripeId(stripe.id || null)
      setPublishKey(stripe.publishable_key || "")
      setSecretKey(stripe.secret_key || "")
      // setCurrencyCode(stripe.currency_code || "")
      setProductId(stripe.product_id || "")
      setWebhookSecret(stripe.webhook_secret || "")
    }
  }, [stripe])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* <h5 className="mb-4">Profile</h5> */}
          {/* Render Breadcrumb */}
          {/* <Breadcrumb title="Profile" breadcrumbItem="" /> */}

          <Row>
            <Col lg="12">
              {(updateSmtpError || updateStripeError) && (
                <Alert
                  color="danger"
                  style={{
                    position: "fixed",
                    top: "20px",
                    left: "50%",
                    transform: "translateX(-50%)",
                    zIndex: 1000,
                  }}
                >
                  {JSON.stringify(
                    updateSmtpError ? updateSmtpError : updateStripeError,
                  )}
                </Alert>
              )}
              {(addSmtpError || addStripeError) && (
                <Alert
                  color="danger"
                  style={{
                    position: "fixed",
                    top: "80px",
                    right: "10px",
                    zIndex: 1000,
                  }}
                >
                  {JSON.stringify(addSmtpError ? addSmtpError : addStripeError)}
                </Alert>
              )}
              {(updateSmtpSuccess || updateStripeSuccess) && (
                <Alert
                  color="success"
                  style={{
                    position: "fixed",
                    top: "80px",
                    right: "10px",
                    zIndex: 1000,
                  }}
                >
                  {updateSmtpSuccess ? updateSmtpSuccess : updateStripeSuccess}
                </Alert>
              )}
              {(addSmtpSuccess || addStripeSuccess) && (
                <Alert
                  color="success"
                  style={{
                    position: "fixed",
                    top: "80px",
                    right: "10px",
                    zIndex: 1000,
                  }}
                >
                  {addSmtpSuccess ? addSmtpSuccess : addStripeSuccess}
                </Alert>
              )}
              <div className="">
                <h3
                  style={{
                    fontSize: "20px",
                    borderBottom: "1px solid #000",
                    paddingBottom: "9px",
                    margin: "18px 0",
                  }}
                >
                  Admin Settings
                </h3>
              </div>

              <Card>
                <div className="">
                  <h3
                    style={{
                      fontSize: "18px",
                      borderBottom: "1px solid #000",
                      paddingBottom: "9px",
                      margin: "18px 18px 0",
                    }}
                  >
                    Manage SMTP Details
                  </h3>
                </div>
                <CardBody>
                  <div className="container-fluid p-0">
                    <div className="row fieldDetails">
                      <div className="col-md-12">
                        <Form.Label>SMTP HOST</Form.Label>
                        <Form.Control
                          type="text"
                          value={host}
                          onChange={e => setHost(e.target.value)}
                          placeholder="Enter Your SMTP Host"
                        />
                      </div>
                      <div className="col-md-12">
                        <Form.Label>SMTP PORT</Form.Label>
                        <Form.Control
                          type="number"
                          value={port}
                          onChange={e => setPort(e.target.value)}
                          placeholder="Enter Your SMTP Port"
                        />
                      </div>
                      <div className="col-md-12">
                        <Form.Label>SMTP User Name</Form.Label>
                        <Form.Control
                          type="text"
                          value={userName}
                          onChange={e => setUsername(e.target.value)}
                          placeholder="Enter Your SMTP User Name"
                        />
                      </div>

                      <div className="col-md-12">
                        <Form.Label>SMTP Email</Form.Label>
                        <Form.Control
                          type="text"
                          value={email}
                          onChange={e => setEmail(e.target.value)}
                          placeholder="Enter Your SMTP User Name"
                        />
                      </div>
                      <div className="col-md-12">
                        <Form.Label>SMTP Password</Form.Label>
                        <Form.Control
                          type="password"
                          value={password}
                          onChange={e => setPassword(e.target.value)}
                          placeholder="Enter your SMTP Password"
                        />
                      </div>

                      {/* <div className="col-md-12">
                        <Form.Label>Encryption</Form.Label>
                        <Form.Control
                          type="text"
                          value={encryption}
                          onChange={e => setEncryption(e.target.value)}
                          placeholder="SSL or TLS"
                        />
                      </div> */}

                      <div style={{ textAlign: "end" }}>
                        <button
                          onClick={() => {
                            const payload = {
                              id: id,
                              default_email: email,
                              smtp_host: host,
                              smtp_port: port,
                              smtp_username: userName,
                              smtp_password: password,
                              use_tls: true,
                              use_ssl: true,
                            }
                            if (smtp === undefined) {
                              dispatch(addSmtp(payload))
                            } else {
                              dispatch(updateSmtp(payload))
                            }
                          }}
                          style={{
                            background: "#556ee6",
                            width: "144px",
                            height: "44px",
                            border: "0",
                            fontSize: "16px",
                            borderRadius: "26px",
                            margin: "18px 0",
                            color: "#fff",
                          }}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>

              <Card>
                <div className="">
                  <h3
                    style={{
                      fontSize: "18px",
                      borderBottom: "1px solid #000",
                      paddingBottom: "9px",
                      margin: "18px 18px 0",
                    }}
                  >
                    Manage Stripe Details
                  </h3>
                </div>
                <CardBody>
                  <div className="container-fluid p-0">
                    <div className="row fieldDetails">
                      <div className="col-md-12">
                        <Form.Label>Stripe Product Id</Form.Label>
                        <Form.Control
                          type="text"
                          value={productId}
                          onChange={e => setProductId(e.target.value)}
                          placeholder="Enter Your Secret Key"
                        />
                      </div>
                      <div className="col-md-12">
                        <Form.Label>Stripe Secret Key</Form.Label>
                        <Form.Control
                          type="text"
                          value={secretKey}
                          onChange={e => setSecretKey(e.target.value)}
                          placeholder="Enter Your Secret Key"
                        />
                      </div>
                      <div className="col-md-12">
                        <Form.Label>Stripe Published Key</Form.Label>
                        <Form.Control
                          type="text"
                          value={publishKey}
                          onChange={e => setPublishKey(e.target.value)}
                          placeholder="Enter Your Published Key"
                        />
                      </div>
                      {/* <div className="col-md-12">
                        <Form.Label>Stripe Currency Code</Form.Label>
                        <Form.Control
                          type="text"
                          value={currencyCode}
                          onChange={e => setCurrencyCode(e.target.value)}
                          placeholder="Enter Your Published Key"
                        />
                      </div> */}
                      <div className="col-md-12">
                        <Form.Label>Stripe Webhook Secret</Form.Label>
                        <Form.Control
                          type="text"
                          value={webhookSecret}
                          onChange={e => setWebhookSecret(e.target.value)}
                          placeholder="Enter Your Manage Webhook Key"
                        />
                      </div>
                      {/* <div className="col-md-12">
                        <Form.Label>SMTP Password</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Your SMTP Password"
                        />
                      </div> */}

                      <div style={{ textAlign: "end" }}>
                        <button
                          onClick={() => {
                            const payload = {
                              id: stripeId,
                              publishable_key: publishKey,
                              secret_key: secretKey,
                              // currency_code: currencyCode,
                              product_id: productId,
                              webhook_secret: webhookSecret,
                            }
                            dispatch(updateStripe(payload))
                          }}
                          style={{
                            background: "#556ee6",
                            width: "144px",
                            height: "44px",
                            border: "0",
                            fontSize: "16px",
                            borderRadius: "26px",
                            margin: "18px 0",
                            color: "#fff",
                          }}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* <h4 className="card-title mb-4">Change User Name</h4> */}

          {/* <Card>
            <CardBody>
              <Form
                className="form-horizontal"
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <div className="form-group">
                  <Label className="form-label">User Name</Label>
                  <Input
                    name="username"
                    // value={name}
                    className="form-control"
                    placeholder="Enter User Name"
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.username || ""}
                    invalid={
                      validation.touched.username && validation.errors.username
                        ? true
                        : false
                    }
                  />
                  {validation.touched.username && validation.errors.username ? (
                    <FormFeedback type="invalid">
                      {validation.errors.username}
                    </FormFeedback>
                  ) : null}
                  <Input name="idx" value={idx} type="hidden" />
                </div>
                <div className="text-center mt-4">
                  <Button type="submit" color="danger">
                    Update User Name
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card> */}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(AdminSetting)
