import {
  GET_PROFILE,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_FAIL,
  UPDATE_PROFILE,
  UPDATE_PROFILE__SUCCESS,
  UPDATE_PROFILE_FAIL,
  RESET_PROFILE_FLAG,
} from "./actionTypes"

export const getProfile = () => ({
  type: GET_PROFILE,
})

export const getProfileSuccess = profile => ({
  type: GET_PROFILE_SUCCESS,
  payload: profile,
})

export const getProfileFail = error => ({
  type: GET_PROFILE_FAIL,
  payload: error,
})

export const updateProfile = value => ({
  type: UPDATE_PROFILE,
  payload: value,
})

export const updateProfileSuccess = user => ({
  type: UPDATE_PROFILE__SUCCESS,
  payload: user,
})

export const updateProfileFail = error => ({
  type: UPDATE_PROFILE_FAIL,
  payload: error,
})

export const resetProfileAlert = () => ({
  type: RESET_PROFILE_FLAG,
})
