import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import { Link, useNavigate } from "react-router-dom"
import { isEmpty } from "lodash"
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import TableContainer from "../../components/Common/TableContainer"
import * as Yup from "yup"
import { useFormik } from "formik"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"
import DeleteModal from "../../components/Common/DeleteModal"

import {
  deletePurchaseEvent,
  getPurchaseEvent,
  getPurhcaseEventApprentice,
} from "store/purchase/actions"

import {
  OrderId,
  EventName,
  Date,
  Total,
  PaymentStatus,
  PaymentMethod,
  CommonField,
  Image,
  CreatedBy,
  Collection,
} from "../Booked-event/BookedCol"

//redux
import { useSelector, useDispatch } from "react-redux"

import {
  Button,
  Col,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody,
} from "reactstrap"
import {
  deleteBookedEvent,
  getBookedEvent,
  getBookedEventDetail,
} from "store/bookedEvent/actions"
import { deleteEvent } from "store/actions"
import MessageModal from "components/Common/MessageModal"

const Purchase = () => {
  const navigate = useNavigate()
  //meta title
  document.title = "Purchase | Tacticulture-Admin"

  const [modal, setModal] = useState(false)
  const [modal1, setModal1] = useState(false)
  const [isEdit, setIsEdit] = useState(false)

  const [orderList, setOrderList] = useState([])
  const [order, setOrder] = useState(null)
  const [showMessage, setShowMessage] = useState(false)

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      orderId: (order && order.orderId) || "",
      eventName: (order && order.eventName) || "",
      orderdate: (order && order.orderdate) || "",
      total: (order && order.total) || "",
      paymentStatus: (order && order.paymentStatus) || "Paid",
      badgeclass: (order && order.badgeclass) || "success",
      paymentMethod: (order && order.paymentMethod) || "Mastercard",
    },
    validationSchema: Yup.object({
      orderId: Yup.string()
        .matches(/[0-9\.\-\s+\/()]+/, "Please Enter Valid Order Id")
        .required("Please Enter Your Order Id"),
      eventName: Yup.string().required("Please Enter Your Event Name"),
      orderdate: Yup.string().required("Please Enter Your Order Date"),
      total: Yup.string()
        .matches(/[0-9\.\-\s+\/()]+/, "Please Enter Valid Amount")
        .required("Total Amount"),
      paymentStatus: Yup.string().required("Please Enter Your Payment Status"),
      badgeclass: Yup.string().required("Please Enter Your Badge Class"),
      paymentMethod: Yup.string().required("Please Enter Your Payment Method"),
    }),
    onSubmit: values => {
      if (isEdit) {
        const updateOrder = {
          id: order ? order.id : 0,
          orderId: values.orderId,
          eventName: values.eventName,
          orderdate: values.orderdate,
          total: values.total,
          paymentStatus: values.paymentStatus,
          paymentMethod: values.paymentMethod,
          badgeclass: values.badgeclass,
        }
        // update order
        dispatch(onUpdateOrder(updateOrder))
        validation.resetForm()
      } else {
        const newOrder = {
          id: Math.floor(Math.random() * (30 - 20)) + 20,
          orderId: values["orderId"],
          eventName: values["eventName"],
          orderdate: values["orderdate"],
          total: values["total"],
          paymentStatus: values["paymentStatus"],
          paymentMethod: values["paymentMethod"],
          badgeclass: values["badgeclass"],
        }
        // save new order
        dispatch(onAddNewOrder(newOrder))
        validation.resetForm()
      }
      toggle()
    },
  })

  // const { purchaseEventApprentice } = useSelector(state => ({
  //   purchaseEventApprentice: state.Purchase.purchaseEventApprentice,
  // }))
  // console.log("----purchaseEventApprentice----", purchaseEventApprentice)

  const toggleViewModal = id => {
    dispatch(getPurhcaseEventApprentice(id))
    navigate(`/purchase-apprentice?purchaseId=${id}`)
    // if (purchaseEventApprentice.length !== 0) {
    // }

    // Check if purchaseEventApprentice already exists
    // if (!purchaseEventApprentice.length) {
    //   dispatch(getPurhcaseEventApprentice(id))
    //   setShowMessage(true)
    // } else {
    //   navigate(`/purchase-apprentice`)
    // }
  }

  const dispatch = useDispatch()
  const { purchaseEvent } = useSelector(state => ({
    purchaseEvent: state.Purchase.purchaseEvent,
  }))

  useEffect(() => {
    if (purchaseEvent && !purchaseEvent.length) {
      dispatch(getPurchaseEvent())
    }
  }, [dispatch])

  const toggle = () => {
    if (modal) {
      setModal(false)
      setOrder(null)
    } else {
      setModal(true)
    }
  }

  const handleOrderClick = arg => {
    const order = arg
    setOrder({
      id: order.id,
      orderId: order.orderId,
      eventName: order.eventName,
      orderdate: order.orderdate,
      total: order.total,
      paymentStatus: order.paymentStatus,
      paymentMethod: order.paymentMethod,
      badgeclass: order.badgeclass,
    })

    setIsEdit(true)

    toggle()
  }

  //delete order
  const [deleteModal, setDeleteModal] = useState(false)

  const onClickDelete = order => {
    setOrder(order)
    setDeleteModal(true)
  }

  const handleDeleteOrder = () => {
    if (order && order.id) {
      dispatch(deletePurchaseEvent(order.id))
      setDeleteModal(false)
    }
  }
  const handleOrderClicks = () => {
    setOrderList("")
    setIsEdit(false)
    toggle()
  }

  const columns = useMemo(
    () => [
      {
        Header: "Purchase ID",
        accessor: "id",
        width: "150px",
        style: {
          textAlign: "center",
          width: "10%",
          background: "#0000",
        },
        filterable: true,
        Cell: cellProps => {
          return <OrderId {...cellProps} />
        },
      },
      {
        Header: "Instructure",
        accessor: "created_by.username",
        filterable: true,
        Cell: cellProps => {
          return <CommonField {...cellProps} />
        },
      },
      {
        Header: "Event Name",
        accessor: "name",
        filterable: true,
        Cell: cellProps => {
          return <CommonField {...cellProps} />
        },
      },
      {
        Header: "Event Price",
        accessor: "cost_per_spot",
        filterable: true,
        Cell: cellProps => {
          return <CommonField {...cellProps} />
        },
      },
      {
        Header: "Collection",
        accessor: "collection",
        filterable: true,
        Cell: cellProps => {
          return <Collection {...cellProps} />
        },
      },
      {
        Header: "Total Apprentice",
        accessor: "total_apprentice",
        filterable: true,
        Cell: cellProps => {
          return <Collection {...cellProps} />
        },
      },
      {
        Header: "View Details",
        accessor: "view",
        disableFilters: true,
        Cell: cellProps => {
          return (
            <Button
              type="button"
              color="primary"
              className="btn-sm btn-rounded"
              onClick={() => toggleViewModal(cellProps.row.original.id)}
            >
              View Details
            </Button>
          )
        },
      },
      {
        Header: "Action",
        accessor: "action",
        disableFilters: true,
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const orderData = cellProps.row.original
                  onClickDelete(orderData)
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          )
        },
      },
    ],
    [],
  )

  const handleRowClick = row => {
    dispatch(getPurhcaseEventApprentice(row.original.id))
    navigate(`/purchase-apprentice?purchaseId=${row.original.id}`)
  }
  return (
    <>
      <React.Fragment>
        <DeleteModal
          show={deleteModal}
          onDeleteClick={handleDeleteOrder}
          onCloseClick={() => setDeleteModal(false)}
        />
        <div className="page-content">
          <div className="container-fluid">
            <h3 className="mb-4">Manage Purchase</h3>
            {/* <Breadcrumbs title="Purchase" breadcrumbItem="List" /> */}
            {/* Render MessageModal conditionally based on the state */}
            {/* {showMessage ? <MessageModal showMessage={true} /> : null} */}
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <TableContainer
                      columns={columns}
                      data={purchaseEvent}
                      getRowProps={row => ({
                        onClick: e => {
                          if (
                            !e.target.closest("[data-column='course_url']") &&
                            !e.target.closest("[data-column='status']") &&
                            !e.target.closest("[data-column='action']")
                          ) {
                            handleRowClick(row)
                          }
                        },
                        style: { cursor: "pointer" }, // Make cursor pointer for rows
                      })}
                      isGlobalFilter={true}
                      isAddOptions={false}
                      handleOrderClicks={handleOrderClicks}
                      customPageSize={10}
                      forFilter={true}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Modal isOpen={modal} toggle={toggle}>
              <ModalHeader toggle={toggle} tag="h4">
                {!!isEdit ? "Edit Order" : "Add Order"}
              </ModalHeader>
              <ModalBody>
                <Form
                  onSubmit={e => {
                    e.preventDefault()
                    validation.handleSubmit()
                    return false
                  }}
                >
                  <Row>
                    <Col className="col-12">
                      <div className="mb-3">
                        <Label className="form-label">Order Id</Label>
                        <Input
                          name="orderId"
                          type="text"
                          placeholder="Insert Order Id"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.orderId || ""}
                          invalid={
                            validation.touched.orderId &&
                            validation.errors.orderId
                              ? true
                              : false
                          }
                        />
                        {validation.touched.orderId &&
                        validation.errors.orderId ? (
                          <FormFeedback type="invalid">
                            {validation.errors.orderId}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">First Name</Label>
                        <Input
                          name="first_name"
                          type="text"
                          placeholder="Insert Event Name"
                          validate={{
                            required: { value: true },
                          }}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.eventName || ""}
                          invalid={
                            validation.touched.eventName &&
                            validation.errors.eventName
                              ? true
                              : false
                          }
                        />
                        {validation.touched.eventName &&
                        validation.errors.eventName ? (
                          <FormFeedback type="invalid">
                            {validation.errors.eventName}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">Order Date</Label>
                        <Input
                          name="orderdate"
                          type="date"
                          // value={orderList.orderdate || ""}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.orderdate || ""}
                          invalid={
                            validation.touched.orderdate &&
                            validation.errors.orderdate
                              ? true
                              : false
                          }
                        />
                        {validation.touched.orderdate &&
                        validation.errors.orderdate ? (
                          <FormFeedback type="invalid">
                            {validation.errors.orderdate}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">Total</Label>
                        <Input
                          name="total"
                          type="text"
                          placeholder="Insert Total Amount"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.total || ""}
                          invalid={
                            validation.touched.total && validation.errors.total
                              ? true
                              : false
                          }
                        />
                        {validation.touched.total && validation.errors.total ? (
                          <FormFeedback type="invalid">
                            {validation.errors.total}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">Payment Status</Label>
                        <Input
                          name="paymentStatus"
                          type="select"
                          className="form-select"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.paymentStatus || ""}
                        >
                          <option>Paid</option>
                          <option>Chargeback</option>
                          <option>Refund</option>
                        </Input>
                        {validation.touched.paymentStatus &&
                        validation.errors.paymentStatus ? (
                          <FormFeedback type="invalid">
                            {validation.errors.paymentStatus}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">Badge Class</Label>
                        <Input
                          name="badgeclass"
                          type="select"
                          className="form-select"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.badgeclass || ""}
                        >
                          <option>success</option>
                          <option>danger</option>
                          <option>warning</option>
                        </Input>
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">Payment Method</Label>
                        <Input
                          name="paymentMethod"
                          type="select"
                          className="form-select"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.paymentMethod || ""}
                        >
                          <option>Mastercard</option>
                          <option>Visa</option>
                          <option>Paypal</option>
                          <option>COD</option>
                        </Input>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="text-end">
                        <button
                          type="submit"
                          className="btn btn-success save-user"
                        >
                          Save
                        </button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </ModalBody>
            </Modal>
          </div>
        </div>
      </React.Fragment>
    </>
  )
}

export default Purchase
