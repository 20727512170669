/* EVENT */
export const GET_EVENTS = "GET_EVENTS"
export const GET_EVENTS_SUCCESS = "GET_EVENTS_SUCCESS"
export const GET_EVENTS_FAIL = "GET_EVENTS_FAIL"

/* UPDATE EVENT */
export const UPDATE_EVENT = "UPDATE_EVENT"
export const UPDATE_EVENT_SUCCESS = "UPDATE_EVENT_SUCCESS"
export const UPDATE_EVENT_FAIL = "UPDATE_EVENT_FAIL"

/* EVENT DETAILS */
export const GET_EVENT_DETAIL = "GET_EVENT_DETAIL"
export const GET_EVENT_DETAIL_SUCCESS = "GET_EVENT_DETAIL_SUCCESS"
export const GET_EVENT_DETAIL_FAIL = "GET_EVENT_DETAIL_FAIL"

/* DELETE EVENT */
export const DELETE_EVENT = "DELETE_EVENT"
export const DELETE_EVENT_SUCCESS = "DELETE_EVENT_SUCCESS"
export const DELETE_EVENT_FAIL = "DELETE_EVENT_FAIL"
