import {
  GET_PLANS,
  GET_PLANS_SUCCESS,
  GET_PLANS_FAIL,
  GET_PLAN_DETAIL,
  GET_PLAN_DETAIL_SUCCESS,
  GET_PLAN_DETAIL_FAIL,
  DELETE_PLAN,
  DELETE_PLAN_SUCCESS,
  DELETE_PLAN_FAIL,
  UPDATE_PLAN,
  UPDATE_PLAN__SUCCESS,
  UPDATE_PLAN_FAIL,
  ADD_PLAN,
  ADD_PLAN_SUCCESS,
  ADD_PLAN_FAIL,
  RESET_ADD_PLAN_FLAG,
} from "./actionTypes"

export const getPlans = () => ({
  type: GET_PLANS,
})

export const getPlansSuccess = plans => ({
  type: GET_PLANS_SUCCESS,
  payload: plans,
})

export const getPlansFail = error => ({
  type: GET_PLANS_FAIL,
  payload: error,
})

export const updatePlan = value => ({
  type: UPDATE_PLAN,
  payload: value,
})

export const updatePlanSuccess = plan => ({
  type: UPDATE_PLAN__SUCCESS,
  payload: plan,
})

export const updatePlanFail = error => ({
  type: UPDATE_PLAN_FAIL,
  payload: error,
})

export const getPlanDetail = id => ({
  type: GET_PLAN_DETAIL,
  payload: id,
})

export const getPlanDetailSuccess = plan => ({
  type: GET_PLAN_DETAIL_SUCCESS,
  payload: plan,
})

export const getPlanDetailFail = error => ({
  type: GET_PLAN_DETAIL_FAIL,
  payload: error,
})

export const deletePlan = id => ({
  type: DELETE_PLAN,
  payload: id,
})

export const deletePlanSuccess = plan => ({
  type: DELETE_PLAN_SUCCESS,
  payload: plan,
})

export const deletePlanFail = error => ({
  type: DELETE_PLAN_FAIL,
  payload: error,
})

export const addPlan = data => ({
  type: ADD_PLAN,
  payload: data,
})

export const addPlanSuccess = plan => ({
  type: ADD_PLAN_SUCCESS,
  payload: plan,
})

export const addPlanFail = error => ({
  type: ADD_PLAN_FAIL,
  payload: error,
})

export const resetAddPlanAlert = () => ({
  type: RESET_ADD_PLAN_FLAG,
})
