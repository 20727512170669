/* PLANS */
export const GET_PLANS = "GET_PLANS"
export const GET_PLANS_SUCCESS = "GET_PLANS_SUCCESS"
export const GET_PLANS_FAIL = "GET_PLANS_FAIL"

/*UPDATE PLAN */
export const UPDATE_PLAN = "UPDATE_PLAN"
export const UPDATE_PLAN__SUCCESS = "UPDATE_PLAN__SUCCESS"
export const UPDATE_PLAN_FAIL = "UPDATE_PLAN_FAIL"

/*PLAN DETAILS */
export const GET_PLAN_DETAIL = "GET_PLAN_DETAIL"
export const GET_PLAN_DETAIL_SUCCESS = "GET_PLAN_DETAIL_SUCCESS"
export const GET_PLAN_DETAIL_FAIL = "GET_PLAN_DETAIL_FAIL"

/**
 * PLAN DELETE
 */
export const DELETE_PLAN = "DELETE_PLAN"
export const DELETE_PLAN_SUCCESS = "DELETE_PLAN_SUCCESS"
export const DELETE_PLAN_FAIL = "DELETE_PLAN_FAIL"

/* ADD NEW PLAN */
export const ADD_PLAN = "ADD_PLAN"
export const ADD_PLAN_SUCCESS = "ADD_PLAN_SUCCESS"
export const ADD_PLAN_FAIL = "ADD_PLAN_FAIL"
export const RESET_ADD_PLAN_FLAG = "RESET_ADD_PLAN_FLAG"
