import { call, put, takeEvery } from "redux-saga/effects"

// Ecommerce Redux States
import {
  GET_BOOKEDEVENTS,
  DELETE_BOOKED_EVENT,
  GET_BOOKEDEVENT_DETAIL,
} from "./actionTypes"
import {
  getBookedEventSuccess,
  getBookedEventFail,
  getBookedEventDetailSuccess,
  getBookedEventDetailFail,
  deleteBookedEventSuccess,
  deleteBookedEventFail,
} from "./actions"
import { showLoader, hideLoader } from "store/actions"

//Include Both Helper File with needed methods

import { get, del } from "helpers/api_helper"

function* fetchBookedEvents() {
  try {
    yield put(showLoader())
    const response = yield call(get, "/api/v1/admin/event/booked-event-list/")
    yield put(getBookedEventSuccess(response.results))
  } catch (error) {
    yield put(getBookedEventFail(error))
  } finally {
    yield put(hideLoader())
  }
}

function* onDeleteBookedEvent({ payload: event }) {
  try {
    yield put(showLoader())
    const response = yield call(
      del,
      `/api/v1/admin/event/booked-event-delete/${event}/`,
    )

    yield put(deleteBookedEventSuccess(event))
  } catch (error) {
    yield put(deleteBookedEventFail(error))
  } finally {
    yield put(hideLoader())
  }
}

function* fetchBookedEventDetail({ payload: event }) {
  try {
    yield put(showLoader())
    const response = yield call(
      get,
      `/api/v1/admin/event/booked-event/${event}`,
    )
    yield put(getBookedEventDetailSuccess(response))
  } catch (error) {
    yield put(getBookedEventDetailFail(error))
  } finally {
    yield put(hideLoader())
  }
}

// function* fetchProducts() {
//   try {
//     const response = yield call(getProducts)
//     yield put(getProductsSuccess(response))
//   } catch (error) {
//     yield put(getProductsFail(error))
//   }
// }

// function* fetchProductDetail({ productId }) {
//   try {
//     const response = yield call(getProductDetail, productId)
//     yield put(getProductDetailSuccess(response))
//   } catch (error) {
//     yield put(getProductDetailFail(error))
//   }
// }

// function* fetchOrders() {
//   try {
//     const response = yield call(getOrders)
//     yield put(getOrdersSuccess(response))
//   } catch (error) {
//     yield put(getOrdersFail(error))
//   }
// }

// function* fetchCartData() {
//   try {
//     const response = yield call(getCartData)
//     yield put(getCartDataSuccess(response))
//   } catch (error) {
//     yield put(getCartDataFail(error))
//   }
// }

// function* fetchCustomers() {
//   try {
//     const response = yield call(getCustomers)
//     yield put(getCustomersSuccess(response))
//   } catch (error) {
//     yield put(getCustomersFail(error))
//   }
// }

// function* onUpdateCustomer({ payload: customer }) {
//   try {
//     const response = yield call(updateCustomer, customer)
//     yield put(updateCustomerSuccess(response))
//   } catch (error) {
//     yield put(updateCustomerFail(error))
//   }
// }

// function* onDeleteCustomer({ payload: customer }) {
//   try {
//     const response = yield call(deleteCustomer, customer)
//     yield put(deleteCustomerSuccess(response))
//   } catch (error) {
//     yield put(deleteCustomerFail(error))
//   }
// }

// function* onAddNewCustomer({ payload: customer }) {
//   try {
//     const response = yield call(addNewCustomer, customer)

//     yield put(addCustomerSuccess(response))
//   } catch (error) {
//     yield put(addCustomerFail(error))
//   }
// }

// function* fetchShops() {
//   try {
//     const response = yield call(getShops)
//     yield put(getShopsSuccess(response))
//   } catch (error) {
//     yield put(getShopsFail(error))
//   }
// }

// function* onUpdateOrder({ payload: order }) {
//   try {
//     const response = yield call(updateOrder, order)
//     yield put(updateOrderSuccess(response))
//   } catch (error) {
//     yield put(updateOrderFail(error))
//   }
// }

// function* onDeleteOrder({ payload: order }) {
//   try {
//     const response = yield call(deleteOrder, order)
//     yield put(deleteOrderSuccess(response))
//   } catch (error) {
//     yield put(deleteOrderFail(error))
//   }
// }

// function* onAddNewOrder({ payload: order }) {
//   try {
//     const response = yield call(addNewOrder, order)
//     yield put(addOrderSuccess(response))
//   } catch (error) {
//     yield put(addOrderFail(error))
//   }
// }

// function* getProductComents() {
//   try {
//     // todo - add product Id to the payload and api
//     const response = yield call(getProductComentsApi)
//     yield put(getProductCommentsSuccess(response))
//   } catch (error) {
//     yield put(getProductCommentsFail(error))
//   }
// }

// function* onLikeComment({ payload: { commentId, productId } }) {
//   try {
//     // todo - add product Id to the payload and api
//     const response = yield call(onLikeCommentApi, commentId, productId)
//     yield put(onLikeCommentSuccess(response))
//   } catch (error) {
//     yield put(onLikeCommentFail(error))
//   }
// }

// function* onLikeReply({ payload: { commentId, productId, replyId } }) {
//   try {
//     // todo - add product Id to the payload and api
//     const response = yield call(onLikeReplyApi, commentId, productId, replyId)
//     yield put(onLikeReplySuccess(response))
//   } catch (error) {
//     yield put(onLikeReplyFail(error))
//   }
// }

// function* onAddReply({ payload: { commentId, productId, replyText } }) {
//   try {
//     const response = yield call(onAddReplyApi, commentId, productId, replyText)
//     yield put(onAddReplySuccess(response))
//   } catch (error) {
//     yield put(onAddReplyFail(error))
//   }
// }

// function* onAddComment({ payload: { productId, commentText } }) {
//   try {
//     const response = yield call(onAddCommentApi, productId, commentText)
//     yield put(onAddCommentSuccess(response))
//   } catch (error) {
//     yield put(onAddCommentFail(error))
//   }
// }

function* bookedEventSaga() {
  yield takeEvery(GET_BOOKEDEVENTS, fetchBookedEvents)
  yield takeEvery(GET_BOOKEDEVENT_DETAIL, fetchBookedEventDetail)
  yield takeEvery(DELETE_BOOKED_EVENT, onDeleteBookedEvent)
}

export default bookedEventSaga
